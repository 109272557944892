:root {
  --tblr-btn-hover-color: #206bc4;
}

.dropdown-menu::-webkit-scrollbar {
  /* scroll bar settings */
  /* Hide scrollbar for Chrome, Safari and Opera */
  display: none !important;
}

.dropdown-menu {
  /* Hide scrollbar for IE, Edge and Firefox */
  max-height: 30vh;
  overflow-y: scroll;
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}
/* .rs-picker-default .rs-picker-toggle,
.primary-border-input
  border: 1px solid #206bc4;
  border-radius: 4px;
 */
.ts-control>input {
  min-width: auto !important;
}

.rs-picker-menu {
  z-index: 9999 !important;
}

.rs-picker-default .rs-picker-toggle {
  z-index: auto;
}

@media (max-width: 768px) {
  .card {
    --tblr-card-spacer-x: 0.5rem;
    --tblr-card-spacer-y: 0.5rem;
    --tblr-card-cap-padding-x: 0.5rem;
    --tblr-card-cap-padding-y: 0.5rem;
  }
}
